<template>
  <upcoming />
</template>

<script>
import Upcoming from '../components/Upcoming';

export default {
  components: {
    Upcoming
  },
  data: () => ({})
};
</script>
