<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" v-if="group">
      <v-list dense nav>
        <v-list-item
          link
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          active-class="no-active"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll color="white">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.smAndDown"
      ></v-app-bar-nav-icon>
      <v-list dense color="transparent">
        <v-list-item two-line link to="/" active-class="no-active">
          <v-list-item-content>
            <v-list-item-title class="title">LAUNCH</v-list-item-title>
            <v-list-item-subtitle>Ralf de Rijcke</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>

      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
        <v-btn
          text
          link
          :to="item.to"
          v-for="item in items"
          :key="item.title"
          active-class="no-active"
        >
          {{ item.title }}
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        { title: 'Home', to: '/', icon: 'mdi-home-outline' },
        { title: 'Launches', to: '/launches', icon: 'mdi-rocket-outline' },
        { title: 'Events', to: '/events', icon: 'mdi-calendar-blank-outline' },
        { title: 'About', to: '/about', icon: 'mdi-information-outline' }
      ]
    };
  },
  computed: {
    group: function() {
      return this.drawer && this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>

<style>
.no-active::before {
  opacity: 0 !important;
}
</style>
