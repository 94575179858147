<template>
  <v-container>
    <v-subheader class="title">Upcomming</v-subheader>

    <v-list subheader>
      <div v-for="(item, i) in items" :key="item.id">
        <div v-if="startNewMonth(i)">
          <v-subheader
            class="subtitle-2 mt-6"
            v-text="
              $moment(item.date)
                .format('MMMM yyyy')
                .toUpperCase()
            "
          ></v-subheader>
        </div>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-avatar>
              <v-avatar
                class="headline"
                v-text="$moment(item.date).format('D')"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
              <v-list-item-subtitle>
                {{ item.type }}
                <span v-if="item.agency">by {{ item.agency }}</span>
                at {{ item.location }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-card class="mx-auto" elevation="0" color="grey lighten-4" tile>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  {{ item.type.toUpperCase() }} at
                  {{ $moment(item.date).format('HH:mm') }}
                </div>
                <v-list-item-title class="headline mb-1"> </v-list-item-title>
                <v-list-item-subtitle>{{ item.location }}</v-list-item-subtitle>
                <span v-if="item.agency" class="overline">
                  {{ item.agency }}
                </span>
                <p>
                  {{ item.description }}
                </p>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn text disabled>
                Details
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-group>
      </div>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['launches', 'events']),

    items() {
      var launchItems = this.launches.map(launch => {
        return {
          id: launch.id,
          date: launch.net,
          name: launch.name,
          description: launch.mission?.description,
          location: launch.pad?.location?.name,
          agency: launch.launch_service_provider?.name,
          type: 'Launch',
          image: launch.image
        };
      });
      var eventItems = this.events.map(event => {
        return {
          id: event.id,
          date: event.date,
          name: event.name,
          description: event.description,
          location: event.location,
          //agency: ,
          type: event.type.name,
          image: event.feature_image
        };
      });

      var items = launchItems.concat(eventItems);
      items.sort(function(a, b) {
        return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
      });

      return items;
    }
  },
  created() {
    this.$store.dispatch('fetchLaunches');
    this.$store.dispatch('fetchEvents');
  },
  methods: {
    startNewMonth(i) {
      return (
        i == 0 ||
        this.$moment(this.items[i].date).month() !=
          this.$moment(this.items[i - 1].date).month()
      );
    }
  }
};
</script>
