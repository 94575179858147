import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/launches',
    name: 'Launches',
    // eslint-disable-next-line
    component: () =>
      import(/* webpackChunkName: "launches" */ '../views/Launches.vue')
  },
  {
    path: '/events',
    name: 'Events',
    // eslint-disable-next-line
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/Events.vue')
  },
  {
    path: '/about',
    name: 'About',
    // eslint-disable-next-line
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
