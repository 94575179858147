import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto';
import axios from 'axios';

Vue.use(Vuex);

function setWithExpiry(key, value) {
  const item = {
    value: value,
    expiry: new Date().getTime() + 1000 * 60 * 60 * 6 // 6 hours in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export default new Vuex.Store({
  state: {
    loadingStatus: 'notLoading',
    events: [],
    launches: []
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loadingStatus = status;
    },
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_LAUNCHES(state, launches) {
      state.launches = launches;
    }
  },
  actions: {
    fetchEvents({ commit, state }) {
      if (state.events.length > 0) {
        console.log('Events from state');
        return;
      }

      var events = getWithExpiry('events');
      if (events) {
        console.log('Events from local storage');
        commit('SET_EVENTS', events);
        return;
      }

      commit('SET_LOADING_STATUS', 'loading');
      axios
        .get('https://ll.thespacedevs.com/2.0.0/event/upcoming/')
        .then(response => {
          console.log('Events from api');
          commit('SET_LOADING_STATUS', 'notLoading');
          setWithExpiry('events', response.data.results);
          commit('SET_EVENTS', response.data.results);
        });
    },
    fetchLaunches({ commit, state }) {
      if (state.launches.length > 0) {
        console.log('Launches from state');
        return;
      }

      var launches = getWithExpiry('launches');
      if (launches) {
        console.log('Launches from local storage');
        commit('SET_LAUNCHES', launches);
        return;
      }

      commit('SET_LOADING_STATUS', 'loading');
      axios
        .get('https://ll.thespacedevs.com/2.0.0/launch/upcoming/')
        .then(response => {
          console.log('Launches from api');
          commit('SET_LOADING_STATUS', 'notLoading');
          setWithExpiry('launches', response.data.results);
          commit('SET_LAUNCHES', response.data.results);
        });
    }
  },
  getters: {
    events(state) {
      return state.events;
    },
    launches(state) {
      return state.launches;
    }
  }
});
